<script>
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import { createUUID } from '@/services/uuid-helper';
import { LaravelEchoClient } from '@/services/laravel-echo';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { createMessageV2 } from '@/services/api/v2/tasks.v2.api';

export default {
  name: 'DhmeStationDashboardNewIssueDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: () => {
    return {
      isLoading: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
      newIssue: {
        name: null,
        message: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedTask: 'dhmeStationDashboard/focusedAssemblyTask',
      moduleId: 'dhmeStationDashboard/moduleId',
    }),

    showModal() {
      return this.displayed;
    },
  },
  methods: {
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.stationDashboard.${path}`, params);
    },
    closeDialog() {
      Object.assign(this.newIssue, { name: null, message: null });
      this.$emit('close');
      this.$refs['issue-form'].reset();
    },
    async saveIssue() {
      if (this.$refs['issue-form'].validate()) {
        try {
          const message = await createMessageV2(this.task.id, {
            message: this.newIssue.name
              ? `${this.newIssue.name}: ${this.newIssue.message}`
              : this.newIssue.message,
          });
          this.task.activities.unshift(message);

          this.$store.commit('showNotification', {
            content: `'${message.value}' placed`,
            color: 'success',
          });
          this.closeDialog();
        } catch (e) {
          this.$store.commit('showNotification', {
            content: e.message,
            color: 'error',
          });
        }
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="showModal" max-width="600px">
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        {{ text('dialogTitle') }}
      </v-card-title>
      <v-card-text>
        <v-form ref="issue-form">
          <ant-input label="Message">
            <template #input-field>
              <v-textarea
                v-model="newIssue.message"
                filled
                dense
                autofocus
                :rules="[rules.required]"
                placeholder="Type here your message..."
              />
            </template>
          </ant-input>
          <ant-input label="Your Name (optional)" is-optional>
            <template #input-field>
              <v-text-field
                v-model="newIssue.name"
                filled
                dense
                placeholder="First name"
              />
            </template>
          </ant-input>
        </v-form>
      </v-card-text>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="isLoading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :loading="isLoading"
          @click="saveIssue"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
</style>
