var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.moduleStatus === 'success')?_c('div',{staticClass:"d-flex flex-column flex-grow-1 overflow-y-hidden"},[(_vm.selectedLocation)?_c('div',{staticClass:"d-flex flex-column flex-1"},[_c('dhme-station-dashboard-toolbar',{attrs:{"websockets-connected":_vm.websocketsConnected}}),_c('div',{staticClass:"d-flex flex-1"},[(_vm.showPlanningSidebar)?_c('div',{staticClass:"px-3 py-2 d-flex flex-column",staticStyle:{"height":"calc(100vh - 115px)"}},[_c('div',{ref:"assemblyTasksScrollContainer",staticClass:"flex-1 pr-2 mb-2 overflow-y-auto flex-scroll-height"},_vm._l((_vm.assemblyTasks),function(task){return _c('dhme-station-dashboard-assembly-task-card',{key:task.id,attrs:{"id":`assembly-task-${task.id}`,"task":task,"focused-task":_vm.focusedTask}})}),1),_c('v-card',{attrs:{"outlined":""},on:{"click":function($event){_vm.linkScanner = true}}},[_c('v-card-text',{staticClass:"d-flex align-center justify-center"},[_c('qr-code',{attrs:{"value":_vm.selectedLocation.id,"options":{ width: 150 }}})],1)],1)],1):_vm._e(),(_vm.focusedTask)?_c('dhme-station-dashboard-viewer',{ref:"station-dashboard-viewer"}):_c('div',{staticClass:"flex-1 d-flex align-center justify-center font-italic"},[_vm._v(" No module on this location ")]),(_vm.assemblySubTasks.length > 0 && _vm.showTasksSidebar)?_c('div',{staticClass:"px-3 py-2 d-flex flex-column",staticStyle:{"height":"calc(100vh - 115px)","max-width":"450px"}},[_c('v-progress-linear',{staticClass:"mb-2",attrs:{"height":"15","value":_vm.totalProgressAcrossSteps,"color":"primary","rounded":""}},[_c('strong',{staticClass:"fs-10",style:({
              color: _vm.getBlackOrWhiteBasedOnBackground(
                _vm.$vuetify.theme.themes.light.primary
              ),
            })},[_vm._v(_vm._s(Math.ceil(_vm.totalProgressAcrossSteps))+"%")])]),_c('v-card',{staticClass:"mb-2 overflow-hidden",attrs:{"outlined":""}},[_c('v-tabs',{attrs:{"center-active":"","show-arrows":"","color":"primary","grow":""},model:{value:(_vm.stepTab),callback:function ($$v) {_vm.stepTab=$$v},expression:"stepTab"}},_vm._l((_vm.assemblyLocationSteps),function(step){return _c('v-tab',{key:`assembly-location-step-${step?.id ? step.id : step}`,on:{"click":_vm.whisperStep}},[_vm._v(_vm._s(step?.id ? `${step?.order} - ${step?.title}` : step)+" "),(_vm.getStepProgress(step) === 100)?_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-check")]):_vm._e()],1)}),1)],1),_c('div',{staticClass:"flex-1 overflow-y-auto flex-scroll-height"},[(!_vm.isStepSignedOff)?_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"full-width mb-2",attrs:{"color":"primary","disabled":_vm.hasOpenTasks}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-checkbox-marked")]),_vm._v("Complete Step")],1)]}}],null,false,4271459090),model:{value:(_vm.signOfMenu),callback:function ($$v) {_vm.signOfMenu=$$v},expression:"signOfMenu"}},[_c('v-card',[_c('v-card-title',[_vm._v("Complete Step")]),_c('v-card-text',[_c('ant-input',{attrs:{"label":"User to sign off"},scopedSlots:_vm._u([{key:"input-field",fn:function(){return [_c('v-text-field',{attrs:{"autofocus":"","filled":"","dense":"","placeholder":"Name","single-line":"","hide-details":""},model:{value:(_vm.signOfUser),callback:function ($$v) {_vm.signOfUser=$$v},expression:"signOfUser"}})]},proxy:true}],null,false,3481204995)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.signOfUser = '';
                    _vm.signOfMenu = false;}}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"success","disabled":_vm.signOfUser.length < 2,"elevation":"0"},on:{"click":_vm.stepSignOff}},[_vm._v("Complete")])],1)],1)],1):_vm._e(),_vm._l((_vm.locationSubTasks
              .filter(
                (t) =>
                  t.task_type?.custom_6?.toString() ===
                  (_vm.assemblyLocationSteps[_vm.stepTab]?.id ??
                    _vm.assemblyLocationSteps[_vm.stepTab])
              )
              .sort(
                (a, b) =>
                  parseFloat(a.task_type.custom_5) -
                  parseFloat(b.task_type.custom_5)
              )),function(subTask){return _c('dhme-station-dashboard-assembly-sub-task-card',{key:subTask.id,attrs:{"task":subTask,"focused-sbs-code":_vm.$refs['station-dashboard-viewer']?.highlightedSbsCode ?? '',"viewer-style":_vm.$refs['station-dashboard-viewer']?.viewerStyle ?? '2d'},on:{"cardClicked":function($event){return _vm.$refs['station-dashboard-viewer'].highlightInModel(subTask)},"scanElement":function($event){_vm.elementScanner = true;
              _vm.elementTask = subTask;},"takePhoto":function($event){_vm.takePictureDialog = true;
              _vm.uploadImageTask = subTask;},"taskClosed":function($event){subTask.status = 'closed';
              _vm.$refs['station-dashboard-viewer'].colorCurrentElements();},"newIssue":function($event){_vm.issueTask = subTask;
              _vm.newIssueDialog = true;}}})}),_vm._l((_vm.previousLocationsSubTasks.sort(
              (a, b) =>
                parseFloat(a.task_type.custom_5) -
                parseFloat(b.task_type.custom_5)
            )),function(subTask){return _c('dhme-station-dashboard-assembly-sub-task-card',{key:subTask.id,attrs:{"from-previous":true,"task":subTask,"focused-sbs-code":_vm.$refs['station-dashboard-viewer']?.highlightedSbsCode ?? '',"viewer-style":_vm.$refs['station-dashboard-viewer']?.viewerStyle ?? '2d'},on:{"cardClicked":function($event){return _vm.$refs['station-dashboard-viewer'].highlightInModel(subTask)},"scanElement":function($event){_vm.elementScanner = true;
              _vm.elementTask = subTask;},"takePhoto":function($event){_vm.takePictureDialog = true;
              _vm.uploadImageTask = subTask;},"taskClosed":function($event){subTask.status = 'closed';
              _vm.$refs['station-dashboard-viewer'].colorCurrentElements();},"newIssue":function($event){_vm.issueTask = subTask;
              _vm.newIssueDialog = true;}}})})],2),(_vm.locationIsLast)?_c('v-btn',{staticClass:"full-width mt-2",attrs:{"color":"primary","disabled":_vm.stepTab !== _vm.assemblyLocationSteps.length - 1},on:{"click":function($event){_vm.completeAssemblyDialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-checkbox-marked-outline")]),_vm._v("Complete Assembly")],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),_c('dhme-station-dashboard-select-location-dialog',{attrs:{"displayed":_vm.locationDialogDisplayed}}),_c('dhme-station-dashboard-complete-assembly-dialog',{attrs:{"displayed":_vm.completeAssemblyDialog},on:{"close":function($event){_vm.completeAssemblyDialog = false},"completed":_vm.init}}),_c('dhme-station-dashboard-new-issue-dialog',{attrs:{"task":_vm.issueTask,"displayed":_vm.newIssueDialog},on:{"close":function($event){_vm.issueTask = {};
      _vm.newIssueDialog = false;}}}),_c('take-picture-dialog',{attrs:{"display-dialog":_vm.takePictureDialog},on:{"closeDialog":function($event){_vm.takePictureDialog = false;
      _vm.uploadImageTask = null;},"uploadImage":_vm.uploadImage}}),_c('v-dialog',{key:"element-scanner",attrs:{"max-width":"90%"},on:{"click:outside":function($event){_vm.elementScanner = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.elementScanner = false}},model:{value:(_vm.elementScanner),callback:function ($$v) {_vm.elementScanner=$$v},expression:"elementScanner"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center text-uppercase headline"},[_vm._v("Scan element ")]),_c('v-divider'),_c('v-card-text',[_c('base-ant-scanner',{attrs:{"decode-parser":_vm.elementIdDecodeParser},on:{"decodedResult":_vm.elementScan}})],1)],1)],1),_c('v-dialog',{key:"location-scanner",attrs:{"max-width":"90%"},on:{"click:outside":function($event){_vm.linkScanner = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.linkScanner = false}},model:{value:(_vm.linkScanner),callback:function ($$v) {_vm.linkScanner=$$v},expression:"linkScanner"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center text-uppercase headline"},[_vm._v("Scan location ")]),_c('v-divider'),_c('v-card-text',[_c('base-ant-scanner',{on:{"decodedResult":_vm.linkDevices}})],1)],1)],1)],1):_c('div',{staticClass:"d-flex justify-center align-center"},[_c('ant-loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }