<script>
import { executeCustomModuleCall } from '@/services/api/module.api';
import { LaravelEchoClient } from '@/services/laravel-echo';
import { mapGetters } from 'vuex';
import { importTasksV2 } from '@/services/api/v2/tasks.v2.api';
import moment from 'moment';

export default {
  name: 'DhmeStationDashboardAssemblySubTaskCard',
  props: {
    task: {
      type: Object,
      required: true,
    },
    viewerStyle: {
      type: String,
      required: true,
    },
    focusedSbsCode: {
      type: String,
      required: true,
    },
    fromPrevious: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      cancelMenu: false,
      completeMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      moduleId: 'dhmeStationDashboard/moduleId',
      selectedLocation: 'dhmeStationDashboard/selectedLocation',
      selectedHall: 'dhmeStationDashboard/selectedHall',
    }),
  },
  methods: {
    cardClicked() {
      this.$emit('cardClicked');
    },

    async closeTask() {
      await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        'closeStationDashboardTask',
        {
          task: this.task.id,
        }
      );
      this.$emit('taskClosed');
      LaravelEchoClient.private(
        `App.Project.${this.project.id}.Module.${this.moduleId}.Location.${this.selectedLocation.id}`
      ).whisper('taskClosed', this.task.id);
    },

    scanElementSetup() {
      if (!this.task.task_type.custom_10) {
        this.$emit('scanElement');
      }
    },

    issueSetup() {
      this.$emit('newIssue');
    },

    takePhoto() {
      this.$emit('takePhoto');
    },
    cancelSubTask() {
      const tasks = importTasksV2([
        {
          id: this.task.id,
          status: 'canceled',
        },
      ]);

      this.task.status = 'canceled';
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatTime(date) {
      return moment(date).format('H:mm');
    },
    text() {
      const [path, ...params] = arguments;
      return this.$t(`modules.stationDashboard.${path}`, params);
    },
  },
};
</script>

<template>
  <v-card
    :disabled="task.status === 'canceled'"
    class="mb-2 sub-task-card-container"
    :color="fromPrevious ? 'grey lighten-3' : 'white'"
    outlined
    :style="{
      cursor: task?.sbscode && viewerStyle === '3d' ? 'pointer' : '',
    }"
    :class="{
      'selected-location-task':
        task?.sbscode === focusedSbsCode && focusedSbsCode,
    }"
    :ripple="false"
    @click.stop="cardClicked"
  >
    <div class="px-3 py-2">
      <div class="d-flex align-center">
        <v-icon
          class="mr-2"
          :color="
            task.status === 'closed'
              ? 'success'
              : task.status === 'canceled'
                ? 'warning'
                : ''
          "
        >
          {{
            task.status === 'closed'
              ? 'mdi-check-circle'
              : task.status === 'canceled'
                ? 'mdi-cancel'
                : task?.sbscode
                  ? 'mdi-cube-outline'
                  : task.task_type.type === 'dhme-quality-assurance'
                    ? 'mdi-form-select'
                    : 'mdi-wrench'
          }}
        </v-icon>
        <span
          :style="{
            'text-decoration':
              task.status === 'canceled' ? 'line-through' : 'none',
          }"
          >{{ task.title }}</span
        >
        <v-spacer />
        <v-tooltip v-if="task?.appendixes?.length > 0" left>
          <template #activator="{ on, attrs }">
            <v-icon class="mx-2" small v-bind="attrs" v-on="on">
              mdi-camera-burst
            </v-icon>
          </template>
          <span>Image uploaded</span>
        </v-tooltip>
        <span
          v-if="
            task.task_type.custom_4 &&
            task.task_type.type === 'dhme-module-assembly-station'
          "
          class="mx-2"
          >{{ task.task_type.custom_4 }}</span
        >

        <v-icon
          v-if="task.status === 'open'"
          class="mr-2"
          dense
          @click.stop="takePhoto"
          >mdi-camera-plus</v-icon
        >
      </div>
    </div>
    <div class="px-3">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-chip
            v-if="fromPrevious"
            v-bind="attrs"
            x-small
            class="mr-2"
            color="primary"
            v-on="on"
            >From previous</v-chip
          >
        </template>
        <span>Task is from a previous location</span>
      </v-tooltip>
      <v-tooltip
        v-if="
          task.status === 'open' &&
          task.sbscode &&
          task.task_type.type === 'dhme-module-assembly-station'
            ? task.task_type.custom_10
            : false
        "
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-chip
            v-if="fromPrevious"
            v-bind="attrs"
            x-small
            class="mr-2"
            color="success"
            v-on="on"
            >Scanned</v-chip
          >
        </template>
        <span>Element validated</span>
      </v-tooltip>
    </div>
    <div
      v-if="task.status === 'open'"
      class="d-flex ant-border-top"
      style="height: 40px"
    >
      <div
        v-if="!selectedHall.automated"
        class="d-flex align-center justify-center"
      >
        <v-menu
          v-model="cancelMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="
                ['3', '4'].includes(task.task_type.custom_9) ||
                !task.task_type.custom_9
              "
              elevation="0"
              small
              tile
              text
              v-bind="attrs"
              class="flex-1 full-width"
              style="height: 100%"
              color="grey"
              v-on="on"
              @click.stop
            >
              <v-icon small class="mr-1">mdi-cancel</v-icon>
              {{ text('notApplicable') }}
            </v-btn>
          </template>

          <v-card>
            <v-card-text
              >Are you sure you want to cancel this task?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn small text @click="cancelMenu = false"> No </v-btn>
              <v-btn small color="warning" elevation="0" @click="cancelSubTask">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <v-divider v-if="!selectedHall.automated" vertical />

      <v-btn
        v-if="
          ['2', '4'].includes(task.task_type.custom_9) ||
          !task.task_type.custom_9
        "
        elevation="0"
        color="warning"
        :text="task.activities.length === 0"
        style="height: 100%; width: 100px"
        tile
        @click.stop="issueSetup"
      >
        <v-icon class="mr-1" small>mdi-message-badge </v-icon>
        {{ text('issue') }}
      </v-btn>
      <v-divider vertical />

      <div class="flex-1 d-flex align-center justify-center">
        <v-btn
          v-if="
            task.sbscode &&
            [
              'dhme-module-assembly-station',
              'dhme-element-assembly-location',
            ].includes(task.task_type.type) &&
            !task.task_type.custom_10
          "
          elevation="0"
          color="info"
          tile
          text
          class="flex-1 full-width"
          style="height: 100%"
          @click.stop="scanElementSetup(task)"
        >
          <v-icon class="mr-1" small>mdi-qrcode-scan </v-icon>
          Validate
        </v-btn>

        <v-menu
          v-model="completeMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="
                task.sbscode &&
                [
                  'dhme-module-assembly-station',
                  'dhme-element-assembly-location',
                ].includes(task.task_type.type)
                  ? task.task_type.custom_10
                  : true
              "
              elevation="0"
              text
              v-bind="attrs"
              tile
              class="flex-1 full-width"
              style="height: 100%"
              color="success"
              v-on="on"
            >
              <v-icon class="mr-1" small>mdi-check-circle </v-icon>
              {{ text('finish') }}
            </v-btn>
          </template>

          <v-card>
            <v-card-text
              >Are you sure you want to complete this task?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn small text @click="completeMenu = false"> No </v-btn>
              <v-btn small color="success" elevation="0" @click="closeTask">
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <v-expansion-panels
      v-if="task.activities.length > 0"
      flat
      class="ant-border-top"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-1">
          <template #default="{ open }">
            <v-fade-transition leave-absolute>
              <div v-if="open">
                <v-icon class="flex-grow-0 mr-2">mdi-message-badge</v-icon>
                {{ text('issues') }}
              </div>
              <v-list-item v-else two-line class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{
                    task.activities[0].value
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="font-italic fs-10"
                      >{{ formatDate(task.activities[0].created_at) }} -
                      {{ formatTime(task.activities[0].created_at) }}</span
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-fade-transition>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="activity in task.activities" :key="activity.id">
              <v-list-item-icon class="mr-2"
                ><v-icon small>mdi-message-reply-text</v-icon></v-list-item-icon
              >
              {{ activity.value }}
              <v-spacer />
              <div class="d-flex flex-column font-italic fs-10">
                <span>{{ formatDate(activity.created_at) }}</span>
                <span>{{ formatTime(activity.created_at) }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<style scoped lang="scss">
.sub-task-card-container {
  min-width: 400px;
}
</style>
